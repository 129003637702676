import styled from "styled-components";

export const SpanTitulo = styled.span`
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const SpanTitulo2 = styled.span`
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 60px;
`;

export const SpanSubtitulo = styled.span`
  color: black;
  font-size: 20px;
  font-weight: bold;
`;

export const DivGeral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FolhaA4 = styled.div`
  width: 220mm;
  height: 308mm;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25mm;
  padding-right: 10mm;
  padding-bottom: 10mm;
  padding-top: 10mm;
  page-break-after: always;
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const TextoInfo = styled.span`
  color: black;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const RodapePdf = styled.div`
  width: 185mm;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  align-items: flex-end;
`;
export const ContainerTextoRodapeH = styled.div`
  color: black;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextoRodapePdf = styled.div`
  font-size: 14px;
  color: black;
`;

export const PDFContainer = styled.div`
  width: 185mm;
  height: 288mm;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
