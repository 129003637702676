import axios from "axios";
import { BASE_URL } from "../common";

export const gateway = axios.create({
  baseURL: `${BASE_URL}/gateway/api`,
  timeout: 60000,
  headers: {
    "content-type": "application/json",
  },
});

export const logar = (body) => {
  return gateway.post("/authenticate", body);
};

export const checkAuthenticate = (token) => {
  return gateway.get("/authenticate", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const account = (token) => {
  return gateway.get("/account", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const createUser = async (body) => {
  return gateway.post("/register", body);
};
