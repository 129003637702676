import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MapContainer, TileLayer } from "react-leaflet";
import { MapaGraticule } from "../../componentes/mapa-leaflet/MapaGraticule";
import {
  getDadosCartograma,
  getDadosImovel,
  getDadosSerieHistoria,
  getDadosTabelaHistoricoUsoSolo,
} from "../../services/api/fusio";
import ImgLogoEmbrapa from "../../assets/logo-embrapa.png";
import ReactToPrint from "react-to-print";
import {
  CelulaTabela,
  ColumMaps,
  ContainerAnoImg,
  ContainerTextoRodape,
  ContainerTextoRodapeH,
  ContainerTitulo,
  CorpoPdf,
  DivQuebraPagina,
  FolhaA4,
  Header,
  ImgsContainer,
  LegendaEstadoContainer,
  LinhaTabela,
  LogoEmbrapa,
  MapsContainer,
  PDFContainer,
  RodapePdf,
  SpanNomeMunicipioFazenda,
  SpanNumeroCar,
  SpanTitulo,
  Tabela,
  TabelaContainer,
  TextoAno,
  TextoAnoTabela,
  TextoCidadeEstadoContainer,
  TextoHeaderTabela,
  TextoInfoTabela,
  TextoLegenda,
  TextoNomeCidade,
  TextoRodape,
  TextoRodapePdf,
  TextoSiglaEstado,
} from "./pdf-serie-historia-estilo";
import { Button } from "@mui/material";
import { decrementLoad, incrementLoad } from "../../redux/reducer";
import { EscalaGrafica } from "./EscalaGrafica";
const data = {
  titulo: "Título do pdf",
  subTitulo: "Subtitulo do pdf This is a header, using header style",
  texto:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis  nomine miserum, animi. Moveat nesciunt triari naturam posset, eveniunt specie deorsus efciat  sermone instituendarum fuisse veniat, eademque mutat debeo. Delectet plerique protervi  diogenem dixerit logikh levius probabo adipiscuntur afcitur, factis magistra inprobitatem aliquo  andriam obiecta, religionis, imitarentur studiis quam, clamat intereant vulgo admonitionem  operis iudex stabilitas vacillare scriptum nixam, reperiri inveniri maestitiam istius eaque  dissentias idcirco gravis, refert suscipiet recte sapiens oportet ipsam terentianus, perpauca  sedatio aliena video.",
};

const dadosTabela = [
  { index: 1, nome: "Série História" },
  { index: 2, nome: "Cartograma" },
  { index: 3, nome: "Tabela Sintese" },
];

export default function PdfSerieHistoria(route) {
  const [jaCarregou, setJaCarregou] = useState(false);
  // const [jaImprimiu, setJaImprimiu] = useState(false);
  let componentRef = useRef();
  const dispatch = useDispatch();
  // dadosPdf
  const [objetoPdf, setObjetoPdf] = useState({});
  const { numeroCar } = useParams();
  const [dadosTabela, setDadosTabela] = useState([]);
  const [mapasPorAno, setMapasPorAno] = useState([]);
  const [bbox, setBbox] = useState([]);

  useEffect(() => {
    setJaCarregou(true);
  }, []);

  useEffect(() => {
    try {
      const carregarDados = async () => {
        dispatch(incrementLoad());
        const { data: dadosImovel } = await getDadosImovel(numeroCar);
        const { data: dados } = await getDadosSerieHistoria(numeroCar);

        setTimeout(() => {
          setBbox([
            [parseFloat(dadosImovel.st_ymin), parseFloat(dadosImovel.st_xmin)],
            [parseFloat(dadosImovel.st_ymax), parseFloat(dadosImovel.st_xmax)],
          ]);
        }, 500);
        setObjetoPdf(dados);
        const teste = Object.keys(dados)
          .map((k) => {
            if (k.includes("imagemusocoberturasolo")) {
              return {
                ano: k.replace("imagemusocoberturasolo", ""),
                img: dados[k],
              };
            }
          })
          .filter((o) => !!o);

        if (teste.length) {
          setMapasPorAno(
            agruparEmArrays(teste, 12).map((m, i) => {
              return agruparEmArrays(m, 4);
            })
          );
        }

        const {
          data: { entry: data },
        } = await getDadosTabelaHistoricoUsoSolo(numeroCar);

        const dataObject = Object.keys(data[0]).map((c) => {
          return { ano: c, dados: JSON.parse(data[0][c]) };
        });

        setDadosTabela(dataObject);

        const headers = [
          "Pastagem",
          "Agricultura",
          "Agropecuária",
          "Silvicultura",
          "Natural não vegetada",
          "Vegetação florestal",
          "Vegetação não florestal",
          "Água",
          "Infraestrutura",
        ];

        setHeadersTabela(headers);
        dispatch(decrementLoad());
      };
      if (jaCarregou) {
        carregarDados();
      }
    } catch (error) {
      dispatch(decrementLoad());
    }
  }, [jaCarregou]);

  function agruparEmArrays(array, tam) {
    const arrayResult = [];
    const tamanho = array.length;
    let dif = 0;
    array.flatMap((item, i) => {
      if (i > 0 && i % tam == 0) {
        arrayResult.push(array.slice(i - tam, i));
        dif = 0;
      }
      if (i == tamanho - 1) {
        arrayResult.push(array.slice(i - dif, i + 1));
      }
      dif++;
    });
    return arrayResult;
  }

  console.log();

  const HeaderPdf = () => (
    <Header>
      <LogoEmbrapa src={ImgLogoEmbrapa} />
      <ContainerTitulo>
        <SpanTitulo>
          Histórico de uso e cobertura da terra do Imóvel rural
        </SpanTitulo>
        <SpanNumeroCar>{`CAR ${numeroCar}`}</SpanNumeroCar>
        <SpanNomeMunicipioFazenda>
          {`${objetoPdf.nom_munici} - ${objetoPdf?.cod_estado}`}
        </SpanNomeMunicipioFazenda>
      </ContainerTitulo>
    </Header>
  );

  const [headersTabela, setHeadersTabela] = useState([]);

  const dePara = (de, para) => {
    if (de === "Pastagem" && para === "Pastagem natural ou plantada") {
      return true;
    }

    if (de === "Agricultura" && para === "Agricultura") return true;

    if (de === "Agropecuária" && para === "Mosaico de agricultura e pastagem")
      return true;

    if (de === "Silvicultura" && para === "Silvicultura") return true;

    if (de === "Natural não vegetada" && para === "Áreas não vegetadas")
      return true;

    if (de === "Vegetação florestal" && para === "Vegetação natural florestal")
      return true;

    if (
      de === "Vegetação não florestal" &&
      para === "Vegetação natural não florestal"
    )
      return true;

    if (de === "Infraestrutura" && para === "Área de influência urbana")
      return true;

    if (de === "Água" && para === "Água e aquicultura") return true;
    return false;
  };
  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ReactToPrint
        onBeforePrint={() => dispatch(incrementLoad())}
        onAfterPrint={() => dispatch(decrementLoad())}
        trigger={() => (
          <div
            style={{
              width: "100vw",
              alignItems: "center",
              backgroundColor: "red",
              position: "relative",
              marginBottom: "50px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{ position: "absolute", left: "50%" }}
            >
              Imprimir
            </Button>
          </div>
        )}
        content={() => componentRef}
      />
      <div
        style={{
          backgroundColor: "#ECF0F6",
        }}
        ref={(el) => (componentRef = el)}
      >
        <div>
          {!!mapasPorAno.length &&
            mapasPorAno.map((mapasFolhaAtual) => (
              <FolhaA4 ref={(el) => (componentRef = el)}>
                {HeaderPdf()}
                <CorpoPdf>
                  <ImgsContainer>
                    <MapsContainer>
                      {mapasFolhaAtual?.map((mapa) => (
                        <ColumMaps>
                          {mapa?.map((mp) => {
                            return (
                              <ContainerAnoImg>
                                <TextoAno>{mp.ano}</TextoAno>
                                <img
                                  src={mp.img}
                                  alt="Usos 2011"
                                  width={160}
                                  height={160}
                                />
                              </ContainerAnoImg>
                            );
                          })}
                        </ColumMaps>
                      ))}
                    </MapsContainer>

                    <LegendaEstadoContainer>
                      <ContainerAnoImg>
                        <TextoLegenda>Cobertura e uso da terra</TextoLegenda>
                        <img
                          src={objetoPdf.legendausoterra}
                          alt="Legenda uso terra"
                          width={150}
                          height={150}
                        />
                      </ContainerAnoImg>
                      <ContainerAnoImg>
                        <TextoCidadeEstadoContainer>
                          <TextoNomeCidade>
                            {objetoPdf.nom_munici}
                          </TextoNomeCidade>

                          <TextoSiglaEstado>
                            {`- ${objetoPdf?.cod_estado}`}
                          </TextoSiglaEstado>
                        </TextoCidadeEstadoContainer>

                        <img
                          src={objetoPdf.imagemestado}
                          alt="Solos"
                          width={130}
                          height={130}
                        />
                      </ContainerAnoImg>
                      <ContainerAnoImg style={{ width: 300, height: 80 }}>
                        <EscalaGrafica bbox={bbox} />
                      </ContainerAnoImg>
                    </LegendaEstadoContainer>
                  </ImgsContainer>

                  <ContainerTextoRodape>
                    <TextoRodape>
                      FONTE: Mapeamento de cobertura e uso da terra do Projeto
                      Mapbiomas e limite do imóvel rural do Cadastro Ambiental
                      Rural - CAR Embrapa: Sistema de apoio à caracterização
                      ambiental de imóveis rurais.
                      <br />
                      http://sacir.cnpma.embrapa.com.br
                    </TextoRodape>
                  </ContainerTextoRodape>
                </CorpoPdf>
                {/* <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <span>legenda</span>
          </div> */}
              </FolhaA4>
            ))}
          <FolhaA4 ref={(el) => (componentRef = el)}>
            <PDFContainer>
              {HeaderPdf()}
              <TabelaContainer>
                <TextoInfoTabela>
                  {
                    "Histórico das áreas de cobertura e uso da terra do imóvel rural desde 1985, por tipo de uso e cobertura. Área em hectares(ha)."
                  }
                </TextoInfoTabela>
                <Tabela>
                  <tbody>
                    <LinhaTabela>
                      <CelulaTabela>
                        <TextoAnoTabela>Ano</TextoAnoTabela>
                      </CelulaTabela>
                      {headersTabela.map((item, index) => {
                        return (
                          <CelulaTabela key={index}>
                            <TextoHeaderTabela>{item}</TextoHeaderTabela>
                          </CelulaTabela>
                        );
                      })}
                    </LinhaTabela>
                    {dadosTabela.map((dado, index) => {
                      return (
                        <LinhaTabela key={index}>
                          <CelulaTabela>{dado.ano}</CelulaTabela>

                          {headersTabela.map((h, i) => {
                            const celula = dado.dados.find((d) =>
                              dePara(h, d.uso)
                            );

                            return celula ? (
                              <CelulaTabela key={i}>
                                {parseFloat(celula.area).toFixed(2)}
                              </CelulaTabela>
                            ) : (
                              <CelulaTabela key={i}>{`0.00`}</CelulaTabela>
                            );
                          })}
                        </LinhaTabela>
                      );
                    })}
                  </tbody>
                </Tabela>
                <TextoInfoTabela>
                  {
                    "Fonte: Mapeamento de cobertura e uso da terra do Projeto Mapbiomas e Limite do imóvel rural do Cadastro Ambiental Rural - CAR."
                  }
                </TextoInfoTabela>
              </TabelaContainer>

              <RodapePdf>
                <ContainerTextoRodapeH>
                  <TextoRodapePdf>
                    Embrapa. Sistema de apoio à caracterização ambiental de
                    imóveis rurais.
                  </TextoRodapePdf>
                  <TextoRodapePdf>
                    http://sacir.cnpma.embrapa.com.br
                  </TextoRodapePdf>
                </ContainerTextoRodapeH>
                <TextoRodapePdf>
                  {new Date().toLocaleDateString()}
                </TextoRodapePdf>
              </RodapePdf>
            </PDFContainer>
            {/* <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <span>legenda</span>
          </div> */}
          </FolhaA4>
        </div>
      </div>
    </div>
  );
}
