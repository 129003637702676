import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoEmbrapa from "../../assets/logo-embrapa.png";
import { Alert, Snackbar, TextField } from "@mui/material";
import { createUser } from "../../services/api/auth";
import { useDispatch } from "react-redux";
import {
  incrementLoad,
  decrementLoad,
  setToastType,
  setToastMessage,
} from "../../redux/reducer";
import {
  ButtonCadastrar,
  DivCard,
  AvisoTermo,
  DivPagina,
  Formulario,
  ImagemIbi,
  SpanEntrar,
  ImageContainer,
  Texto,
  LinkTermoAviso,
  TextoCadastro,
} from "./cadastro-estilo";
import Checkbox from "@mui/material/Checkbox";
import pdfAvisoDePrivacidade from "../../PdfDownloads/AvisoDePrivacidade.pdf";
import pdfTermoDeUso from "../../PdfDownloads/TermoDeUso.pdf";

const Cadastro = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [usuario, setUsuario] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmacaoSenha, setConfirmacaoSenha] = useState("");
  const [exibirErros, setExibirErros] = useState(false);
  const [usuarioComEspaco, setUsuarioComEspaco] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [termoAceito, setTermoAceito] = useState(false);
  const refConfirPassword = useRef();
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  const [alert, setAlert] = useState({
    mensagem: "Erro interno",
    tipo: "error",
  });

  const setTypeError = (e) => {
    const {
      response: {
        data: { title: errorMessage },
      },
    } = e;
    if (errorMessage === "Login name already used!") {
      dispatch(setToastType("warning"));
      dispatch(setToastMessage("E-mail já cadastrado!"));
      return;
    }

    switch (e?.response?.status) {
      case 400:
        dispatch(setToastType("warning"));
        dispatch(setToastMessage("Dados Inválidos!"));
        break;
      case 401:
        dispatch(setToastType("warning"));
        dispatch(setToastMessage("Sem autorização!"));
        break;
      default:
        dispatch(setToastType("error"));
        dispatch(setToastMessage("Erro Interno!"));
    }
  };

  function dadosValidos() {
    return (
      !!usuario &&
      !!email &&
      !!senha &&
      senha.length > 3 &&
      !!confirmacaoSenha &&
      senha === confirmacaoSenha
    );
  }

  async function handleCadastrar(event) {
    event.preventDefault();
    if (!termoAceito) {
      dispatch(setToastType("warning"));
      dispatch(
        setToastMessage("É necessário aceitar os termos para continuar!")
      );
      return;
    }
    setExibirErros(true);
    if (!dadosValidos()) {
      setTypeError({ response: { status: 400 } });
      return;
    }

    try {
      dispatch(incrementLoad());
      const { status } = await createUser({
        login: email,
        firstName: usuario,
        lastName: "",
        email: email,
        imageUrl: "",
        activated: false,
        langKey: "pt-br",
        createdDate: new Date(),
        lastModifiedDate: new Date(),
        authorities: ["ROLE_USER"],
        password: senha,
      });

      dispatch(decrementLoad());
      if (status === 201) {
        dispatch(setToastType("success"));
        dispatch(
          setToastMessage(
            "Usuário registrado com sucesso, verifique seu email para ativação!"
          )
        );
        navigate("/login");
      }
    } catch (error) {
      setTypeError(error);
      dispatch(decrementLoad());
      console.log("Erro ao criar usuário: ", error);
    }
  }

  return (
    <DivPagina>
      {larguraTela > 700 && (
        <ImageContainer ImageContainer>
          <Texto>Sistema de Apoio</Texto>
          <Texto>à Caracterização de</Texto>
          <Texto>Imóveis Rurais</Texto>
        </ImageContainer>
      )}
      <DivCard style={{ width: larguraTela > 700 ? "40%" : "100%" }}>
        <AvisoTermo>
          <LinkTermoAviso
            href={pdfAvisoDePrivacidade}
            download={"AvisoDePrivacidade.pdf"}
          >
            Aviso de Privacidade
          </LinkTermoAviso>
          <LinkTermoAviso href={pdfTermoDeUso} download={"TermoDeUso.pdf"}>
            Termo de uso
          </LinkTermoAviso>
        </AvisoTermo>

        <Formulario>
          <TextoCadastro>Cadastro</TextoCadastro>

          <TextField
            required={true}
            placeholder="Nome do Usuário"
            variant="outlined"
            type="text"
            color="info"
            id="username"
            value={usuario}
            InputProps={{
              style: {
                borderRadius: "50px",
                height: 40,
              },
            }}
            error={exibirErros && (!usuario || usuarioComEspaco) ? true : false}
            helperText={exibirErros && !usuario ? "Campo obrigatório" : ""}
            style={{ width: larguraTela > 700 ? "70%" : "100%" }}
            onChange={(event) => {
              setUsuarioComEspaco(
                !!event.target.value.split("").find((letra) => letra === " ")
              );
              console.log(
                "object :>> ",
                !!event.target.value.split("").find((letra) => letra === " ")
              );
              setUsuario(event.target.value);
            }}
          />

<TextoCadastro>Dados de Login</TextoCadastro>
          <TextField
            required={true}
            placeholder="E-mail"
            variant="outlined"
            type="text"
            color="info"
            id="email"
            value={email}
            InputProps={{
              style: {
                borderRadius: "50px",
                height: 40,
              },
            }}
            error={exibirErros && !email ? true : false}
            helperText={exibirErros && !email ? "Campo obrigatório" : ""}
            style={{ width: larguraTela > 700 ? "70%" : "100%" }}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />

          <TextField
            required={true}
            placeholder="Senha"
            variant="outlined"
            color="info"
            type="password"
            InputProps={{
              style: {
                borderRadius: "50px",
                height: 40,
                alignItems: "center",
              },
            }}
            error={exibirErros && senha.length < 4 ? true : false}
            helperText={
              exibirErros && !senha
                ? "Campo obrigatório"
                : exibirErros && senha.length < 4
                ? "Deve ter 4 ou mais caracteres!"
                : ""
            }
            style={{ width: larguraTela > 700 ? "70%" : "100%" }}
            id="password"
            value={senha}
            onChange={(event) => {
              setSenha(event.target.value);
            }}
          />

          <TextField
            ref={refConfirPassword}
            required={true}
            placeholder="Confirmação de senha"
            variant="outlined"
            color="info"
            type="password"
            InputProps={{
              style: {
                borderRadius: "50px",
                height: 40,
                alignItems: "center",
              },
            }}
            style={{ width: larguraTela > 700 ? "70%" : "100%" }}
            id="confirm-password"
            value={confirmacaoSenha}
            error={
              exibirErros && senha != confirmacaoSenha
                ? true
                : exibirErros && !confirmacaoSenha
                ? true
                : false
            }
            helperText={
              exibirErros && senha != confirmacaoSenha
                ? "Senhas diferentes!"
                : exibirErros && !confirmacaoSenha
                ? "Campo obrigatório"
                : ""
            }
            onChange={(event) => {
              setConfirmacaoSenha(event.target.value);
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              value={termoAceito}
              onChange={() => setTermoAceito((prev) => !prev)}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Declaro que li e concordo integralmente com:</span>
              <span>Termo de Uso e a Política de Privacidade</span>
            </div>
          </div>

          <ButtonCadastrar onClick={handleCadastrar}>
            <SpanEntrar>Cadastrar</SpanEntrar>
          </ButtonCadastrar>
        </Formulario>

        <ImagemIbi src={LogoEmbrapa} alt="Minha Imagem" />
      </DivCard>
    </DivPagina>
  );
};

export default Cadastro;
