import React, { useEffect, useState } from "react";
import {
  DivGeral,
  SpanTitulo,
  DivContainer,
  SpanSubtitulo,
  ContentSearch,
  ContentResultado,
  ContainerComBorda,
  ContainerMapa,
  ContainerInfoMapa,
  InfoMapa,
} from "./inicio-estilo";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { TabelaPaginada } from "../../componentes/TabelaPaginada";
import { useNavigate } from "react-router-dom";
import { MapaLeaflet } from "../../componentes/mapa-leaflet/MapaLeaflet";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { getDadosImovel } from "../../services/api/fusio";
import { useDispatch } from "react-redux";
import {
  decrementLoad,
  incrementLoad,
  setToastMessage,
  setToastType,
} from "../../redux/reducer";

const Inicio = () => {
  const [exibeResultado, setExibeResultado] = useState(false);
  const [bbox, setBbox] = useState(null);
  const [numeroCar, setNumeroCar] = useState("");
  // SE-2800100-AAEE29E76BEE481FA3967C296B1D62B7
  const [dadosImovel, setDadosImovel] = useState({});
  const dispatch = useDispatch();
  const [openError, setOpenError] = useState(false);
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  useEffect(() => {
    const atualizarLarguraTela = () => {
      setLarguraTela(window.innerWidth);
    };

    window.addEventListener("resize", atualizarLarguraTela);

    return () => {
      window.removeEventListener("resize", atualizarLarguraTela);
    };
  }, []);

  const [error, setError] = useState({
    mensagem: "Erro interno",
    tipo: "error",
  });

  const colunasTabela = [
    {
      nome: "Indice",
    },
    {
      nome: "Nome",
    },
  ];

  const dadosTabela = [
    { index: 1, nome: "Série Histórica" },
    { index: 2, nome: "Cartograma" },
    { index: 3, nome: "Tabela Sintese" },
  ];

  const optionsCombo = [
    {
      nome: (row) => "Gerar PDF",
      desabilitarOpcao: () => false,
      icon: () => <PictureAsPdfIcon />,
      onClick: (user) => {
        if (user.index === 1) {
          window.open(`/pdfSerieHistoria/${numeroCar}`);
        }
        if (user.index === 2) {
          window.open(`/pdfCartogramaPrint/${numeroCar}`);
        }
        if (user.index === 3) {
          window.open(`/pdfTabelaSintese/${numeroCar}`);
        }
      },
    },
  ];

  const milionSeparator = (str) => {
    if (str === "NaN") return "0,00";
    const [a, b] = str.split(",");
    const c = Array.from(a)
      .reverse()
      .map((item, index) => {
        return index > 0 && index % 3 == 0 ? `${item}.` : item;
      })
      .reverse()
      .join("");
    return [c, b].join(",");
  };

  const pesquisar = async () => {
    if (!numeroCar) {
      dispatch(setToastType("warning"));
      dispatch(setToastMessage("Digite o numero do CAR!"));
      return;
    }
    try {
      dispatch(incrementLoad());
      const { data: response } = await getDadosImovel(numeroCar);
      console.log("response :>> ", response);
      setDadosImovel({
        areaTotal: milionSeparator(
          parseFloat(response.num_area).toFixed(2).replace(/\./g, ",")
        ),
        vegetacao: milionSeparator(
          parseFloat(response.vegetacao).toFixed(2).replace(/\./g, ",")
        ),
        areaAgricola: milionSeparator(
          parseFloat(response.agricultura).toFixed(2).replace(/\./g, ",")
        ),
        codigoImovel: response.cod_imovel,
      });

      setBbox([
        [parseFloat(response.st_ymin), parseFloat(response.st_xmin)],
        [parseFloat(response.st_ymax), parseFloat(response.st_xmax)],
      ]);
      console.log(
        "object :>> ",
        JSON.stringify([
          [parseFloat(response.st_ymin), parseFloat(response.st_xmin)],
          [parseFloat(response.st_ymax), parseFloat(response.st_xmax)],
        ])
      );
      setExibeResultado(true);
    } catch (e) {
      console.log("Erro ao pesquisar: ", e);
      dispatch(setToastType("error"));
      dispatch(setToastMessage("Número CAR inválido!"));
      setExibeResultado(false);
    } finally {
      dispatch(decrementLoad());
    }
  };

  const getTamanhoMapa = () => {
    if (larguraTela >= 1200) {
      return "500px";
    } else if (larguraTela >= 900 && larguraTela < 1199) {
      return "400px";
    } else if (larguraTela >= 500 && larguraTela < 899) {
      return "300px";
    }
    return "70vw";
  };

  const Resultado = () => (
    <ContentResultado $marginCelular={larguraTela < 700}>
      <ContainerComBorda style={{ marginBottom: 20 }}>
        <ContainerMapa>
          <div
            style={{
              width: `${getTamanhoMapa()}`,
              height: "300px",
              marginTop: 10,
              marginBottom: 10,
              backgroundColor: "green",
            }}
          >
            <MapaLeaflet codigoImovel={dadosImovel.codigoImovel} bbox={bbox} />
          </div>
          <ContainerInfoMapa>
            <InfoMapa>Área total: {dadosImovel.areaTotal}ha</InfoMapa>
            <InfoMapa>Área agrícola: {dadosImovel.areaAgricola}ha</InfoMapa>
            <InfoMapa>Vegetação: {dadosImovel.vegetacao}ha</InfoMapa>
          </ContainerInfoMapa>
        </ContainerMapa>
      </ContainerComBorda>
      <TabelaPaginada
        colunas={colunasTabela}
        showAcoes={true}
        acoes={optionsCombo}
        dados={dadosTabela}
        showPaginacao={false}
      />
    </ContentResultado>
  );

  return (
    <div className={exibeResultado ? "fundo" : "background-degrade-fundo"}>
      <DivGeral>
        <SpanTitulo style={{ fontSize: larguraTela > 700 ? 38 : 18 }}>
          Sistema de Apoio à Caracterização de Imóveis Rurais
        </SpanTitulo>
        <DivContainer>
          <SpanSubtitulo style={{ fontSize: larguraTela > 700 ? 30 : 18 }}>
            Obtenha Informações sobre o seu imóvel
          </SpanSubtitulo>
          <ContentSearch>
            <TextField
              style={{ width: "80%" }}
              value={numeroCar}
              placeholder="Digite o numero do CAR"
              onChange={(object) => {
                setNumeroCar(object.target.value);
                setExibeResultado(false);
              }}
            />
            <Button
              style={{ width: "8%", marginLeft: "2%" }}
              onClick={() => {
                setNumeroCar("");
                setExibeResultado(false);
              }}
              variant="outlined"
            >
              <DeleteIcon />
            </Button>
            <Button
              variant="contained"
              style={{ width: "8%", marginLeft: "2%" }}
              onClick={pesquisar}
            >
              <SearchIcon />
            </Button>
          </ContentSearch>
          {exibeResultado && Resultado()}
        </DivContainer>
      </DivGeral>
    </div>
  );
};

export default Inicio;
