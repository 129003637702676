import { CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToastMessage, setToastType } from "../redux/reducer";

export const PublicRoute = ({ component: Component, ...rest }) => {
  const load = useSelector((state) => state.persistedReducer.load);
  const [openAlert, setOpenAlert] = useState(false);
  const toastType = useSelector((state) => state.persistedReducer.toastType);
  const dispatch = useDispatch();
  const toastMessage = useSelector(
    (state) => state.persistedReducer.toastMessage
  );

  useEffect(() => {
    if (toastMessage) {
      setOpenAlert(true);
    }
  }, [toastMessage]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={() => {
          setOpenAlert(false);
          dispatch(setToastMessage(""));
          dispatch(setToastType(""));
        }}
      >
        <Alert
          onClose={() => {
            setOpenAlert(false);
            dispatch(setToastMessage(""));
          }}
          severity={toastType || "success"}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      {!!load && (
        <div className="background-load">
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#024634" }} />
          </div>
        </div>
      )}
      <Component />
    </>
  );
};
