import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { useEffect, useState } from "react";
import "./pdfStyles.css";
import imagem from "./img.png";
import { useNavigate, useParams } from "react-router-dom";
import { imgBase64 } from "../../utils/imgBase64Teste";
import { incrementLoad, decrementLoad } from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
const data = {
  titulo: "Título do pdf",
  subTitulo: "Subtitulo do pdf This is a header, using header style",
  texto:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Confectum ponit legam, perferendis  nomine miserum, animi. Moveat nesciunt triari naturam posset, eveniunt specie deorsus efciat  sermone instituendarum fuisse veniat, eademque mutat debeo. Delectet plerique protervi  diogenem dixerit logikh levius probabo adipiscuntur afcitur, factis magistra inprobitatem aliquo  andriam obiecta, religionis, imitarentur studiis quam, clamat intereant vulgo admonitionem  operis iudex stabilitas vacillare scriptum nixam, reperiri inveniri maestitiam istius eaque  dissentias idcirco gravis, refert suscipiet recte sapiens oportet ipsam terentianus, perpauca  sedatio aliena video.",
};

const dadosTabela = [
  { index: 1, nome: "Série História" },
  { index: 2, nome: "Cartograma" },
  { index: 3, nome: "Tabela Sintese" },
];

export default function PdfCartograma(route) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const load = useSelector((state) => state.persistedReducer.load);
  const [dataUrl, setDataUrl] = useState("");
  const [base64ImgLocal, setbase64ImgLocal] = useState("");

  // dadosPdf
  const [objetoPdf, setObjetoPdf] = useState({});
  const { numeroCar } = useParams();
  console.log("numeroCar :>> ", numeroCar);

  const dadoSelecionado = dadosTabela.find((o) => o.index == 1);

  useEffect(() => {
    const carregarDados = async () => {
      const dados = await promiseComTimeout({
        titulo: "Projeto Rural Sustentável Cerrado",
        fazenda: "Fazenda São Matheus, Três Lagoas - MG",
        linkImagem1: "https://www.keycdn.com/img/support/cors-lg.webp",
        linkImagem2: "https://www.keycdn.com/img/support/cors-lg.webp",
      });
      const imagem1 = await getBase64ImageFromURL(dados.linkImagem1);
      const imagem2 = await getBase64ImageFromURL(dados.linkImagem2);
      setObjetoPdf({
        titulo: dados.titulo,
        fazenda: dados.fazenda,
        imagem1,
        legendaImagem1: imagem2,
      });
    };

    carregarDados();
  }, []);

  useEffect(() => {
    loadPdf();
  }, [objetoPdf]);

  //   useEffect(() => {
  //     loadPdf();
  //   }, [base64ImgLocal]);

  const promiseComTimeout = (resposta) => {
    return new Promise((resolve) => setTimeout(() => resolve(resposta), 300));
  };

  const getBase64ImageFromURL = (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  };

  async function getBase64(img) {
    // const imgUrl = await fetch(img);
    const imgUrl = await getBase64ImageFromURL(
      "https://www.keycdn.com/img/support/cors-lg.webp"
    );
    setbase64ImgLocal(imgUrl);
  }

  //Definição do documento pdf
  const doc = {
    content: [
      {
        text: objetoPdf?.titulo,
        style: "header",
      },
      "\n", //Quebra de linha
      {
        text: objetoPdf?.fazenda,
        style: "subheader",
      },
      "\n", //Quebra de linha
      {
        style: "tabelaSemBorda",
        table: {
          body: [
            [
              {
                image: objetoPdf?.imagem1,
                width: 250,
              },
              {
                image: objetoPdf?.legendaImagem1,
                width: 250,
              },
            ],
          ],
        },
      },
      "\n",
      {
        style: "tabelaSemBorda",
        table: {
          body: [
            [
              {
                image: objetoPdf?.imagem1,
                width: 250,
              },
              {
                image: objetoPdf?.legendaImagem1,
                width: 250,
              },
            ],
          ],
        },
      },
      {
        style: "tabelaSemBorda",
        table: {
          body: [
            [
              {
                image: objetoPdf?.imagem1,
                width: 250,
              },
              {
                image: objetoPdf?.legendaImagem1,
                width: 250,
              },
            ],
          ],
        },
      },
      {
        style: "tabelaSemBorda",
        table: {
          body: [
            [
              {
                image: objetoPdf?.imagem1,
                width: 250,
              },
              {
                image: objetoPdf?.legendaImagem1,
                width: 250,
              },
            ],
          ],
        },
      },
      "\n",
      {
        style: "tabelaSemBorda",
        table: {
          body: [
            [
              {
                text: "escala grafica",
                width: 510,
                height: 100,
              },
            ],
          ],
        },
      },
      {
        style: "tabelaSemBorda",
        table: {
          body: [
            [
              {
                image: objetoPdf?.imagem1,
                width: 250,
                height: 100,
              },
              {
                image: objetoPdf?.legendaImagem1,
                width: 250,
                height: 100,
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      subheader: {
        fontSize: 15,
        bold: true,
        alignment: "center",
      },
      quote: {
        italics: true,
      },
      tabelaSemBorda: {
        border: "", // ou border: '',
      },
      small: {
        fontSize: 8,
      },
    },
  };

  async function loadPdf() {
    try {
      dispatch(incrementLoad());
      await getBase64(imagem);
      const pdf = pdfMake.createPdf(doc);
      pdf.getDataUrl((url) => {
        setDataUrl(url);
      });

      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve("ok");
        }, 1000);
      });
      dispatch(decrementLoad());
    } catch (e) {
      console.log("Erro ao carregar pdf: ", e);
      dispatch(decrementLoad());
    }
  }

  return (
    <div>
      <iframe className="iframePdf" src={dataUrl} />
    </div>
  );
}
