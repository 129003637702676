import React, { useState } from "react";
import {
  DivGeral,
  SpanTitulo,
  DivContainer,
  Texto,
  TextoTitulo,
} from "./contato-estilo";

const Contato = () => {
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  return (
    <div className="background-degrade-fundo">
      <DivGeral>
        <SpanTitulo style={{ fontSize: larguraTela > 700 ? 38 : 18 }}>
          Sistema de Apoio à Caracterização de Imóveis Rurais
        </SpanTitulo>
        <DivContainer>
          <TextoTitulo>Contato</TextoTitulo>
          <Texto style={{ marginTop: 20 }}>Texto contato</Texto>
        </DivContainer>
      </DivGeral>
    </div>
  );
};

export default Contato;
