import styled from "styled-components";

export const SpanTitulo = styled.span`
  color: #024634;
  font-size: 38px;
  font-weight: bold;
  width: 80%;
`;

export const DivGeral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DivContainer = styled.div`
  width: 90%;
  height: calc(100vh - 200px);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  gap: 20px;
  border-radius: 5px;
  overflow: auto;
`;

export const Texto = styled.p`
  color: #024634;
  margin: 0;
`;

export const TextoTitulo = styled.p`
  color: #696969;
  font-size: 30px;
  margin: 0;
`;
