import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoEmbrapa from "../../assets/logo-embrapa.png";
import {
  Alert,
  IconButton,
  Input,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { account, logar } from "../../services/api/auth";
import { useDispatch } from "react-redux";
import {
  incrementLoad,
  decrementLoad,
  setUser,
  setToken,
  clearReducer,
  setToastType,
  setToastMessage,
} from "../../redux/reducer";
import { BASE_URL } from "../../services/common";
import {
  ButtonEntrar,
  ButtonCadastrar,
  DivCard,
  AvisoTermo,
  DivPagina,
  Formulario,
  ImagemIbi,
  SpanEntrar,
  ImageContainer,
  Texto,
  LinkTermoAviso,
  TextoLogin,
  TextoLoginContainer,
  TextoEmailSenha,
} from "./login-estilo";

import pdfAvisoDePrivacidade from "../../PdfDownloads/AvisoDePrivacidade.pdf";
import pdfTermoDeUso from "../../PdfDownloads/TermoDeUso.pdf";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    dispatch(clearReducer());
  }, []);

  const showError = (e) => {
    switch (e?.response?.status) {
      case 400:
        dispatch(setToastType("warning"));
        dispatch(setToastMessage("Dados Inválidos!"));
        break;
      case 401:
        dispatch(setToastType("warning"));
        dispatch(setToastMessage("Sem autorização!"));
        break;
      default:
        dispatch(setToastType("error"));
        dispatch(setToastMessage("Erro Interno!"));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      dispatch(incrementLoad());
      const {
        data: { id_token },
      } = await logar({
        username: username,
        password: password,
        rememberMe: true,
      });

      const { data: dadosUsuario } = await account(id_token);

      dispatch(setUser(dadosUsuario));
      dispatch(setToken(id_token));
      dispatch(decrementLoad());
      navigate("/inicio");
    } catch (e) {
      showError(e);
      console.log("erro ao logar :>> ", e);
      dispatch(decrementLoad());
    }
  };
  useEffect(() => {
    console.log(larguraTela);
  }, [larguraTela]);

  const textoLoginEmailSenha = "(e-mail e senha)";
  return (
    <DivPagina>
      {larguraTela > 700 && (
        <ImageContainer ImageContainer>
          <Texto>Sistema de Apoio</Texto>
          <Texto>à Caracterização de</Texto>
          <Texto>Imóveis Rurais</Texto>
        </ImageContainer>
      )}
      <DivCard style={{ width: larguraTela > 700 ? "40%" : "100%" }}>
        {larguraTela < 700 && (
          <div style={{ marginBottom: 30, textAlign: "center" }}>
            <h4>Sistema de Apoio</h4>
            <h4>à Caracterização de</h4>
            <h4>Imóveis Rurais</h4>
          </div>
        )}
        <AvisoTermo>
          <LinkTermoAviso
            href={pdfAvisoDePrivacidade}
            download={"AvisoDePrivacidade.pdf"}
          >
            Aviso de Privacidade
          </LinkTermoAviso>
          <LinkTermoAviso href={pdfTermoDeUso} download={"TermoDeUso.pdf"}>
            Termo de uso
          </LinkTermoAviso>
        </AvisoTermo>

        <Formulario>
          <TextoLoginContainer>
            <TextoLogin>Login</TextoLogin>
            <TextoEmailSenha>{textoLoginEmailSenha}</TextoEmailSenha>
          </TextoLoginContainer>

          <TextField
            placeholder="E-mail"
            variant="outlined"
            type="text"
            color="info"
            id="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            InputProps={{
              style: {
                borderRadius: "50px",
                height: 40,
              },
            }}
            style={{ width: larguraTela > 700 ? "70%" : "90%" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />

          <Input
            disableUnderline
            variant="outlined"
            placeholder="Senha"
            color="info"
            style={{
              borderRadius: "50px",
              height: 40,
              alignItems: "center",
              border: "1px solid #00000055",
              width: larguraTela > 700 ? "70%" : "90%",
              paddingLeft: 10,
              paddingRight: 20,
            }}
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <ButtonEntrar
            style={{ width: larguraTela > 700 ? "70%" : "90%" }}
            onClick={handleSubmit}
          >
            <SpanEntrar>Entrar</SpanEntrar>
          </ButtonEntrar>
          <ButtonCadastrar
            style={{ width: larguraTela > 700 ? "70%" : "90%" }}
            onClick={() => navigate("/cadastro")}
          >
            <SpanEntrar>Cadastrar</SpanEntrar>
          </ButtonCadastrar>
        </Formulario>

        <ImagemIbi src={LogoEmbrapa} alt="Minha Imagem" />
      </DivCard>
    </DivPagina>
  );
};

export default Login;
