import styled from "styled-components";

export const SpanTitulo = styled.span`
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const SpanSubtitulo = styled.span`
  color: black;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
`;
export const SpanNumeroCar = styled.span`
  color: black;
  font-size: 12px;
  display: block;
`;
export const SpanNomeMunicipioFazenda = styled.span`
  color: black;
  font-size: 12px;
  display: block;
`;

export const DivGeral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 185mm;
  justify-content: space-between;
`;

export const FolhaA4 = styled.div`
  width: 220mm;
  height: 308mm;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25mm;
  padding-right: 10mm;
  padding-bottom: 10mm;
  padding-top: 10mm;
  page-break-after: always;
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ContainerTitulo = styled.div`
  width: 156mm;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoEmbrapa = styled.img`
  width: 29mm;
  height: 20mm;
`;
export const CorpoPdf = styled.div`
  width: 185mm;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const ImgsContainer = styled.div`
  width: 140mm;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
`;

export const TextoAno = styled.h3`
  color: black;
`;

export const ContainerAnoImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  rotate: -90deg;
`;
export const ContainerTextoRodape = styled.div`
  width: 10mm;
  position: absolute;
  bottom: 20mm;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 25px;
`;

export const TextoRodape = styled.span`
  width: 160mm;
  font-size: 12px;
  color: black;
  rotate: -90deg;
`;

export const PDFContainer = styled.div`
  width: 185mm;
  height: 288mm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  width: 175mm;
  /* height: 288mm; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const Tabela = styled.table`
  width: 175mm;
  border-collapse: collapse;
`;

export const LinhaTabela = styled.tr`
  border-bottom: 1px solid black;
  height: 14px;
`;

export const LinhaTabelaUltima = styled.tr`
  border-bottom: 2px solid black;
  height: 14px;
`;
export const LinhaHeaderTabela = styled.tr`
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  height: 40px;
`;

export const CelulaTabelaNumeros = styled.td`
  font-size: 14px;
  text-align: end;
  /* border: 2px solid black; */
  width: 20px;
  text-align: center;
`;

export const CelulaTabela = styled.td`
  font-size: 14px;
  /* border: 2px solid black; */
  width: 20px;
`;

export const CelulaTabelaHeader = styled.td`
  font-size: 14px;
  text-align: center;
  /* border: 2px solid black; */
  width: 20px;
  height: 100px;
  transform: rotate(-90deg);
`;

export const TextoHeaderTabela = styled.div`
  height: 100px;
  rotate: -90deg;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RodapePdf = styled.div`
  width: 185mm;
  display: flex;
  justify-content: space-between;
`;
export const ContainerTextoRodapeH = styled.div`
  color: black;
  font-size: 14px;
`;

export const TextoRodapePdf = styled.div`
  font-size: 14px;
  color: black;
`;

export const TextoSubTitulo = styled.span`
  font-size: 16px;
  color: black;
  text-align: justify;
`;
