import styled from "styled-components";

export const SpanTitulo = styled.span`
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const SpanSubtitulo = styled.span`
  color: black;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
`;
export const SpanNumeroCar = styled.span`
  color: black;
  font-size: 12px;
  display: block;
`;
export const SpanNomeMunicipioFazenda = styled.span`
  color: black;
  font-size: 12px;
  display: block;
`;

export const DivGeral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 185mm;
  justify-content: space-between;
`;

export const FolhaA4 = styled.div`
  width: 220mm;
  height: 308mm;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25mm;
  padding-right: 10mm;
  padding-bottom: 10mm;
  padding-top: 10mm;
  page-break-after: always;
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ContainerTitulo = styled.div`
  width: 156mm;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoEmbrapa = styled.img`
  width: 29mm;
  height: 15mm;
`;
export const CorpoPdf = styled.div`
  width: 185mm;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const ImgsContainer = styled.div`
  width: 165mm;
  height: 270mm;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const TextoCidadeEstadoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextoNomeCidade = styled.h3`
  color: black;
  max-width: 15ch;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
`;
export const TextoSiglaEstado = styled.h3`
  color: black;
`;

export const TextoAno = styled.h3`
  color: black;
`;

export const TextoLegenda = styled.span`
  color: black;
  font-size: 12px;
`;

export const ContainerAnoImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  rotate: -90deg;
`;
export const ContainerTextoRodape = styled.div`
  width: 10mm;
  position: absolute;
  bottom: 40mm;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TextoRodape = styled.span`
  width: 200mm;
  font-size: 12px;
  color: black;
  rotate: -90deg;
`;

export const PDFContainer = styled.div`
  width: 185mm;
  height: 288mm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const Tabela = styled.table`
  width: 185mm;
  border-collapse: collapse;
`;

export const LinhaTabela = styled.tr`
  border: 1px solid black;
  height: 10px;
`;

export const CelulaTabela = styled.td`
  font-size: 14px;

  padding-left: 10px;
  text-align: center;
`;

export const TextoAnoTabela = styled.span`
  color: black;
`;

export const TextoHeaderTabela = styled.div`
  height: 100px;

  rotate: -90deg;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
`;

export const RodapePdf = styled.div`
  width: 185mm;
  display: flex;
  justify-content: space-between;
`;
export const ContainerTextoRodapeH = styled.div`
  color: black;
  font-size: 14px;
`;

export const TextoRodapePdf = styled.div`
  font-size: 14px;
  color: black;
`;

export const MapsContainer = styled.div`
  width: 100%;
  /* height: 500px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColumMaps = styled.div`
  width: 33%;
  height: 225mm;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
`;

export const LegendaEstadoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 100px;
  padding-left: 20px;
`;

export const TabelaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

export const TextoInfoTabela = styled.span`
  color: black;
`;
