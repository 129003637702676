import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MapContainer, TileLayer } from "react-leaflet";
import { MapaGraticule } from "../../componentes/mapa-leaflet/MapaGraticule";
import {
  getDadosTabelaSintese,
  getDadosTabelaSintese2008,
} from "../../services/api/fusio";
import ImgLogoEmbrapa from "../../assets/logo-embrapa.png";
import ReactToPrint from "react-to-print";
import {
  CelulaTabela,
  CelulaTabelaHeader,
  CelulaTabelaNumeros,
  ContainerTextoRodapeH,
  ContainerTitulo,
  FolhaA4,
  Header,
  LinhaHeaderTabela,
  LinhaTabela,
  LinhaTabelaUltima,
  LogoEmbrapa,
  PDFContainer,
  RodapePdf,
  SpanNomeMunicipioFazenda,
  SpanNumeroCar,
  SpanTitulo,
  Tabela,
  TableContainer,
  TextoRodapePdf,
  TextoSubTitulo,
} from "./pdf-tabela-sintese-estilo";
import { Button } from "@mui/material";
import { decrementLoad, incrementLoad } from "../../redux/reducer";

export default function PdfTabelaSintese(route) {
  const [jaCarregou, setJaCarregou] = useState(false);
  // const [jaImprimiu, setJaImprimiu] = useState(false);
  let componentRef = useRef();

  // dadosPdf
  const [dados, setDados] = useState([]);
  const [dados2008, setDados2008] = useState([]);
  const [nomeMunicipio, setNomeMunicipio] = useState("");
  const { numeroCar } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("trocando :>> ");
    setJaCarregou(true);
  }, []);

  useEffect(() => {
    try {
      const carregarDados = async () => {
        dispatch(incrementLoad());
        const responses = await Promise.all([
          getDadosTabelaSintese(numeroCar),
          getDadosTabelaSintese2008(numeroCar),
        ]);

        setNomeMunicipio(
          `${responses[0]?.data?.nom_munici} - ${numeroCar.split("-")[0]}`
        );
        responses.map((dado, index) => {
          if (index === 0) {
            setDados(montaLista(dado.data));
          } else {
            setDados2008(montaLista(dado.data));
          }
        });
        dispatch(decrementLoad());
      };
      if (jaCarregou) {
        carregarDados();
      }
    } catch (error) {
      dispatch(decrementLoad());
      console.log("Erro buscar dados: ", error);
    }
  }, [jaCarregou]);

  const montaObjeto = (
    dados,
    nome,
    nomeString,
    aptoOuInapto = null,
    de = [null, null, null, null]
  ) => {
    return {
      nome: nomeString,
      area: dados[`${nome}${aptoOuInapto || ""}`],
      propriedade: dados[`pct_${nome}${aptoOuInapto || ""}`],
      areaDeclividadeAte8: dados[`${nome}_ate_8${aptoOuInapto || ""}`],
      areaDeclividade8A20:
        dados[`${nome}${de[0] || ""}_8_ate_20${aptoOuInapto || ""}`],
      areaDeclividade20a45:
        dados[`${nome}${de[1] || ""}_20_ate_45${aptoOuInapto || ""}`],
      areaDeclividade45a100:
        dados[`${nome}${de[2] || ""}_45_ate_100${aptoOuInapto || ""}`],
      areaDeclividadeAcima100:
        dados[`${nome}${de[3] || ""}_acima_100${aptoOuInapto || ""}`],
    };
  };

  const montaLista = (dados) => {
    const listaRetorno = [];

    [
      {
        chave: "amz",
        titulo: "Amazônia Legal",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "declividade",
        titulo: "Propriedade",
        aptoOuInapto: null,
        de: ["_de", "_de", "_de", "_de"],
      },
      {
        chave: "vegetacao",
        titulo: "Vegetação",
        aptoOuInapto: null,
        de: ["de", "de", "de", "de"],
      },
      {
        chave: "vegetacao_livre",
        titulo: "Vegetação Livre",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "vegetacao_livre",
        titulo: "Vegetação Livre Apto",
        aptoOuInapto: "_apto",
        de: [null, null, null, null],
      },
      {
        chave: "vegetacao_livre",
        titulo: "Vegetação Livre Inapto",
        aptoOuInapto: "_inapto",
        de: [null, null, null, null],
      },
      {
        chave: "agropecuario",
        titulo: "Agropecuário",
        aptoOuInapto: null,
        de: ["_de", "_de", null, null],
      },
      {
        chave: "agropecuario_livre",
        titulo: "Ágropecuario Livre",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "agropecuario_livre",
        titulo: "Ágropecuario Livre Apto",
        aptoOuInapto: "_apto",
        de: [null, null, null, null],
      },
      {
        chave: "agropecuario_livre",
        titulo: "Ágropecuario Livre Inapto",
        aptoOuInapto: "_inapto",
        de: [null, null, null, null],
      },
      {
        chave: "ti",
        titulo: "TI",
        aptoOuInapto: null,
        de: ["_de", "_de", "_de", "_de"],
      },
      {
        chave: "vegetacao_ti",
        titulo: "Vegetação TI",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "agro_ti",
        titulo: "Agro TI",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "ucpi",
        titulo: "UCPI",
        aptoOuInapto: null,
        de: ["_de", "_de", "_de", "_de"],
      },
      {
        chave: "vegetacao_ucpi",
        titulo: "Vegetação UCPI",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "agro_ucpi",
        titulo: "Agro UCPI",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "agua",
        titulo: "Água",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "infra",
        titulo: "Infra",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
      {
        chave: "outros",
        titulo: "Outros",
        aptoOuInapto: null,
        de: [null, null, null, null],
      },
    ]
      .sort((a, b) => (a.chave < b.chave ? -1 : 1))
      .map(({ chave, titulo, aptoOuInapto, de }) =>
        listaRetorno.push(montaObjeto(dados, chave, titulo, aptoOuInapto, de))
      );

    return listaRetorno;
  };

  const textosHeaderTabela = [
    "Ano 2021",
    "Área (ha)",
    "% Propriedade",
    "Área até 8% de Declividade (ha)",
    "Área de 8% a 20% de Declividade (ha)",
    "Área de 20% a 45% de Declividade (ha)",
    "Área de 45% a 100% de Declividade (ha)",
    "Área acima de 100% Declividade (ha)",
  ];

  const textosHeaderTabelaPag2 = [
    "Ano 2008",
    "Área (ha)",
    "% Propriedade",
    "Área até 8% de Declividade (ha)",
    "Área de 8% a 20% de Declividade (ha)",
    "Área de 20% a 45% de Declividade (ha)",
    "Área de 45% a 100% de Declividade (ha)",
    "Área acima de 100% Declividade (ha)",
  ];

  const texto = () => (
    <TextoSubTitulo>
      Área de remanescentes, uso agropecuário, terras indigenas, unidades de
      conservação de proteção integral, água, infraestrutura em hectares e em
      porcentagem do imóvel rural com a discriminação da quantidade de área de
      remanescentes e de uso agropecuário em terras indígenas, unidades de
      conservação e áreas com aptidão agrícola favorável ao uso agropecuário com
      a distribuição por declividade até 8% e de 8 a 20%. Área em hectares (ha).
    </TextoSubTitulo>
  );

  const texto2 = () => (
    <TextoSubTitulo style={{ marginTop: 10 }}>
      Notas: <strong>TI</strong> - Terra indígena; <strong>UCPI</strong> -
      Unidade de conservação integral; <strong>Livre</strong> - Áreas do imóvel
      que estão fora de Terras Indígenas, de Unidades de Conservação de Proteção
      Integral e abaixo de 100% de declividade. <strong>Agropecuário</strong> -
      somatório de todos os usos agrícolas: Pastagem, Agricultura, Silvicultura;{" "}
      <strong>Apto</strong> - Terras não alagáveis com aptidão agrícola
      favorável ao uso agropecuário.
    </TextoSubTitulo>
  );

  const exibeValor = (valor) => {
    if (!valor) return "0.00";
    return parseFloat(valor).toFixed(2);
  };

  const exibeValorPct = (valor) => {
    if (!valor) return "0.00";
    const valorFloat = parseFloat(valor) * 100;
    if (valorFloat > 100) return "100.00";
    return (parseFloat(valor) * 100).toFixed(2);
  };

  const formataColuna = (coluna) => {};

  const HeaderPdf = () => (
    <Header>
      <LogoEmbrapa src={ImgLogoEmbrapa} />
      <ContainerTitulo>
        <SpanTitulo>
          Informações geoespaciais e condições agroambientais do imóvel rural
        </SpanTitulo>
        <SpanNumeroCar>{`CAR ${numeroCar}`}</SpanNumeroCar>
        <SpanNomeMunicipioFazenda>{nomeMunicipio}</SpanNomeMunicipioFazenda>
      </ContainerTitulo>
    </Header>
  );

  const TabelaComDados = (itens, header) => (
    <Tabela>
      <tbody>
        <LinhaHeaderTabela>
          {header.map((item, index) => {
            return <CelulaTabelaHeader key={index}>{item}</CelulaTabelaHeader>;
          })}
        </LinhaHeaderTabela>
        {itens.map((dado, index) => {
          return (
            <>
              {index === itens?.length - 1 ? (
                <LinhaTabelaUltima key={index}>
                  <CelulaTabela>{dado.nome}</CelulaTabela>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.area)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValorPct(dado.propriedade)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividadeAte8)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividade8A20)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividade20a45)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividade45a100)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividadeAcima100)}
                  </CelulaTabelaNumeros>
                </LinhaTabelaUltima>
              ) : (
                <LinhaTabela key={index}>
                  <CelulaTabela>{dado.nome}</CelulaTabela>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.area)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValorPct(dado.propriedade)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividadeAte8)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividade8A20)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividade20a45)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividade45a100)}
                  </CelulaTabelaNumeros>
                  <CelulaTabelaNumeros>
                    {exibeValor(dado.areaDeclividadeAcima100)}
                  </CelulaTabelaNumeros>
                </LinhaTabela>
              )}
            </>
          );
        })}
      </tbody>
    </Tabela>
  );

  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ReactToPrint
        onBeforePrint={() => dispatch(incrementLoad())}
        onAfterPrint={() => dispatch(decrementLoad())}
        trigger={() => (
          <div
            style={{
              width: "100vw",
              alignItems: "center",
              backgroundColor: "red",
              position: "relative",
              marginBottom: "50px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{ position: "absolute", left: "50%" }}
            >
              Imprimir
            </Button>
          </div>
        )}
        content={() => componentRef}
      />
      <div
        style={{
          backgroundColor: "#ECF0F6",
        }}
        ref={(el) => (componentRef = el)}
      >
        <div>
          <FolhaA4 ref={(el) => (componentRef = el)}>
            <PDFContainer>
              {HeaderPdf()}
              {texto()}
              <TableContainer>
                {TabelaComDados(dados, textosHeaderTabela)}
                {texto2()}
              </TableContainer>
              <RodapePdf>
                <ContainerTextoRodapeH>
                  <TextoRodapePdf>
                    Embrapa. Sistema de apoio à caracterização ambiental de
                    imóveis rurais.
                  </TextoRodapePdf>
                  <TextoRodapePdf>
                    http://sacir.cnpma.embrapa.com.br
                  </TextoRodapePdf>
                </ContainerTextoRodapeH>
                <TextoRodapePdf>
                  {new Date().toLocaleDateString()}
                </TextoRodapePdf>
              </RodapePdf>
            </PDFContainer>
          </FolhaA4>
          <FolhaA4 ref={(el) => (componentRef = el)}>
            <PDFContainer>
              {HeaderPdf()}
              <TableContainer>
                {TabelaComDados(dados2008, textosHeaderTabelaPag2)}
                {texto2()}
              </TableContainer>
              <RodapePdf>
                <ContainerTextoRodapeH>
                  <TextoRodapePdf>
                    Embrapa. Sistema de apoio à caracterização ambiental de
                    imóveis rurais.
                  </TextoRodapePdf>
                  <TextoRodapePdf>
                    http://sacir.cnpma.embrapa.com.br
                  </TextoRodapePdf>
                </ContainerTextoRodapeH>
                <TextoRodapePdf>
                  {new Date().toLocaleDateString()}
                </TextoRodapePdf>
              </RodapePdf>
            </PDFContainer>
          </FolhaA4>
        </div>
      </div>
    </div>
  );
}
