import { Button, Icon } from "@mui/material";
import L, { CRS } from "leaflet";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import MapIcon from "@mui/icons-material/Map";
import CityIcon from "@mui/icons-material/LocationCity";
import HouseIcon from "@mui/icons-material/HouseOutlined";
import PinIcon from "@mui/icons-material/Grass";
import { BASE_URL } from "../../services/common";

export const MapaLeaflet = ({ codigoImovel, bbox }) => {
  const [map, setMap] = useState(null);
  const [layerUf, setLayerUf] = useState(null);
  const [layerCity, setLayerCity] = useState(null);
  const [showLair2021, setShowLair2021] = useState(false);
  const [layerLair2021, setLayerLair2021] = useState(false);
  const [showLayerUf, setShowLayerUf] = useState(true);
  const [showLayerCity, setShowLayerCity] = useState(true);

  useEffect(() => {
    setLayerUf(buildBaseLayerYoux("terroir:uf"))
    // setLayerUf(buildBaseLayer("lair:uf"));
    setLayerCity(buildBaseLayer("lair:municipio", "municipio_branco"));
    setLayerLair2021(buildLayerImovel("lair:2021"));
  }, []);

  useEffect(() => {
    if (showLair2021) {
      layerLair2021?.addTo(map);
    } else {
      map?.removeLayer(layerLair2021);
    }
  }, [showLair2021, layerLair2021]);

  useEffect(() => {
    if (showLayerCity) {
      layerCity?.addTo(map);
    } else {
      map?.removeLayer(layerCity);
    }
  }, [showLayerCity, layerCity]);

  useEffect(() => {
    if (showLayerUf) {
      layerUf?.addTo(map);
    } else {
      map?.removeLayer(layerUf);
    }
  }, [layerUf, showLayerUf]);

  useEffect(() => {
    if (!map || !codigoImovel) return;
    carregarLayerImovel();
  }, [map, codigoImovel]);

  const carregarLayerImovel = () => {
    const lairArea = buildLayerImovel("lair:area_imovel");
    lairArea?.addTo(map);
    map?.fitBounds(bbox);
  };

  const buildLayerImovel = (layer) => {
    return L.tileLayer.wms(
      `${BASE_URL}/geoserver/lair/wms`,
      {
        layers: layer,
        format: "image/png",
        transparent: true,
        version: "1.1.0",
        crs: CRS.EPSG900913,
        cql_filter: `cod_imovel='${codigoImovel}'`,
      }
    );
  };

  const buildBaseLayerYoux = (layer, service = "gwc/service/wms") => {
    return L.tileLayer.wms(
      "https://api.youxgroup.com.br/geoserver/" + service,
      {
        layers: layer,
        format: "image/png",
        transparent: true,
        version: "1.1.0",
        crs: CRS.EPSG900913,
      }
    );
  };

  const buildBaseLayer = (layer, styles = null) => {
    return L.tileLayer.wms(
      `${BASE_URL}/geoserver/lair/wms`,
      {
        layers: layer,
        format: "image/png",
        transparent: true,
        version: "1.1.0",
        crs: CRS.EPSG900913,
        styles,
      }
    );
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <MapContainer
        ref={setMap}
        attributionControl={false}
        center={[-15.793889, -53.882778]}
        zoom={4}
        minZoom={4}
        maxZoom={20}
        style={{ height: "300px", width: "auto" }}
        id="map"
      >
        <div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: 80,
              marginLeft: 12,
              width: 30,
              height: 30,
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="leaflet-control toggle-add-layers"
            onClick={() => setShowLayerUf((prev) => !prev)}
            title="Adicionar camadas de estados"
          >
            <MapIcon style={{ color: "#024634" }} />
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: 5,
              marginLeft: 12,
              width: 30,
              height: 30,
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="leaflet-control toggle-add-layers"
            onClick={() => setShowLayerCity((prev) => !prev)}
            title="Adicionar camadas de cidades"
          >
            <CityIcon style={{ color: "#024634" }} />
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: 5,
              marginLeft: 12,
              width: 30,
              height: 30,
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="leaflet-control toggle-add-layers"
            onClick={() => setShowLair2021((prev) => !prev)}
            title="Adicionar camadas de usos"
          >
            <PinIcon style={{ color: "#024634" }} />
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: 5,
              marginLeft: 12,
              width: 30,
              height: 30,
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="leaflet-control toggle-add-layers"
            onClick={() => map?.fitBounds(bbox)}
            title="Encontrar Imóvel"
          >
            <HouseIcon style={{ color: "#024634" }} />
          </div>
        </div>
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
      </MapContainer>
    </div>
  );
};
