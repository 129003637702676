import L, { CRS } from "leaflet";
import "../../utils/utilsGeo";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { BASE_URL } from "../../services/common";

export const MapaGraticule = ({ codigoImovel, bbox }) => {
  const [map, setMap] = useState(null);

  const [layerCity, setLayerCity] = useState(null);

  useEffect(() => {
    setLayerCity(buildBaseLayer("lair:municipio"));
  }, []);

  useEffect(() => {
    layerCity?.addTo(map);
  }, [layerCity]);

  useEffect(() => {
    if (!map || !codigoImovel) return;
    carregarLayerImovel();
  }, [map, codigoImovel]);

  useEffect(() => {
    if (bbox) {
      map.fitBounds(bbox);
      console.log("ZOOM >>", map.getZoom());
    }
  }, [bbox]);

  const carregarLayerImovel = () => {
    const lairArea = buildLayerImovel("lair:area_imovel");
    lairArea?.addTo(map);

    L.latlngGraticule({
      showLabel: true,
      zoomInterval: [
        { start: 2, end: 3, interval: 30 },
        { start: 4, end: 4, interval: 10 },
        { start: 5, end: 7, interval: 5 },
        { start: 8, end: 9, interval: 0.5 },
        { start: 10, end: 11, interval: 0.1 },
        { start: 12, end: 40, interval: 0.05 },
      ],
      weight: 0.5,

      color: "#7a7979#636363",
    }).addTo(map);
  };

  const buildLayerImovel = (layer) => {
    return L.tileLayer.wms(`${BASE_URL}/geoserver/lair/wms`, {
      layers: layer,
      format: "image/png",
      transparent: true,
      version: "1.1.0",
      styles: "imovel_destaque",
      crs: CRS.EPSG900913,
      cql_filter: `cod_imovel='${codigoImovel}'`,
    });
  };

  const buildBaseLayer = (layer) => {
    return L.tileLayer.wms(`${BASE_URL}/geoserver/lair/wms`, {
      layers: layer,
      format: "image/png",
      transparent: true,
      version: "1.1.0",
      crs: CRS.EPSG900913,
    });
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <MapContainer
        dragging={false}
        zoomControl={false}
        touchZoom={false}
        zoom={false}
        ref={setMap}
        attributionControl={false}
        center={[-15.793889, -53.882778]}
        // zoom={8}
        style={{ height: 300, width: 200 }}
        id="map"
      >
        {/* <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" /> */}
      </MapContainer>
    </div>
  );
};
