import axios from "axios";
import { BASE_URL } from "../common";

export const getDadosImovel = (numeroCar) => {
  //   return promiseComTimeout({
  //     st_xmin: "-37.73629499759854",
  //     st_ymin: "-9.707790085212842",
  //     st_xmax: "-37.667685527582655",
  //     st_ymax: "-9.639180615196949",
  //     cod_imovel: "SE-2801207-C2F3B8F33B5844EDB21EA1EDAC984DE4",
  //     num_area: "1061.74765093861",
  //     cod_estado: "SE",
  //     nom_munici: "Canind\u00e9 de S\u00e3o Francisco",
  //     num_modulo: "0.1068",
  //     vegetacao: "424699.0603754426",
  //     agricultura: "201732.05367833574"
  // })
  return axios.get(`${BASE_URL}/sacir/imovel?cod_imovel=${numeroCar}`);
};

export const getDadosCartograma = (numeroCar) => {
  return axios.get(
    `${BASE_URL}/sacir/uso_solo_declividade?cod_imovel=${numeroCar}`
  );
};

export const getDadosSerieHistoria = (numeroCar) => {
  return axios.get(
    `${BASE_URL}/sacir/cartograma_uso_cobertura_solo?cod_imovel=${numeroCar}`
  );
};

export const getDadosTabelaHistoricoUsoSolo = (numeroCar) => {
  return axios.get(
    `${BASE_URL}/sacir/tabela_historico_uso_solo?cod_imovel=${numeroCar}`
  );
};

export const getDadosTabelaSintese = (numeroCar) => {
  return axios.get(`${BASE_URL}/sacir/tabela_sintese?cod_imovel=${numeroCar}`);
};

export const getDadosTabelaSintese2008 = (numeroCar) => {
  return axios.get(
    `${BASE_URL}/sacir/tabela_sintese2008?cod_imovel=${numeroCar}`
  );
};
