import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ContainerTextoRodapeH,
  FolhaA4,
  PDFContainer,
  RodapePdf,
  SpanTitulo,
  SpanTitulo2,
  TextoInfo,
  TextoRodapePdf,
} from "./pdf-cartograma-estilo";
import { MapaGraticule } from "../../componentes/mapa-leaflet/MapaGraticule";
import { getDadosCartograma, getDadosImovel } from "../../services/api/fusio";
import ImgLogoEmbrapa from "../../assets/logo-embrapa.png";
import ImgZoom from "../../assets/zoom.png";
import {
  ContainerTitulo,
  Header,
  LogoEmbrapa,
  SpanNomeMunicipioFazenda,
  SpanNumeroCar,
} from "./pdf-serie-historia-estilo";

import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
import { decrementLoad, incrementLoad } from "../../redux/reducer";
import { EscalaGrafica } from "./EscalaGrafica";

export default function PdfCartogramaPrint(route) {
  const [bbox, setBbox] = useState(null);
  const [bboxEscala, setBboxEscala] = useState(null);
  const dispatch = useDispatch();

  // dadosPdf
  const [objetoPdf, setObjetoPdf] = useState({});
  const { numeroCar } = useParams();
  let componentRef = useRef();

  useEffect(() => {
    const carregarDados = async () => {
      const { data: dados } = await getDadosCartograma(numeroCar);
      setObjetoPdf({
        titulo: "Cobertura e uso da terra do imóvel rural",
        titulo: "Uso das terras, solos e relevo do imóvel rural",
        estado: dados.cod_estado,
        municipioFazenda: dados.nom_munici,
        imagemEstadoImovel: dados.imagemestado,
        st_xmin: dados.st_xmin,
        st_ymin: dados.st_ymin,
        st_xmax: dados.st_xmax,
        st_ymax: dados.st_ymax,
        legendaUsoTerra: dados.legendausoterra,
        imagemUsoTerra2008: dados.imagemusoterra2008,
        imagemUsoTerra2011: dados.imagemusoterra2011,
        imagemUsoTerra2016: dados.imagemusoterra2016,
        imagemUsoTerra2021: dados.imagemusoterra2021,
        legendaSolo: dados.legendasolo,
        imagemSolo: dados.imagemsolo,
        legendaDeclividade: dados.legendadeclividade,
        imagemDeclividade: dados.imagemdeclividade,
      });

      const { data: dadosImovel } = await getDadosImovel(numeroCar);
      setBboxEscala([
        [parseFloat(dadosImovel.st_ymin), parseFloat(dadosImovel.st_xmin)],
        [parseFloat(dadosImovel.st_ymax), parseFloat(dadosImovel.st_xmax)],
      ]);

      setBbox([
        [parseFloat(dados.st_ymin), parseFloat(dados.st_xmin)],
        [parseFloat(dados.st_ymax), parseFloat(dados.st_xmax)],
      ]);
    };

    carregarDados();
  }, []);

  const HeaderPdf = ({ titulo }) => (
    <Header>
      <LogoEmbrapa src={ImgLogoEmbrapa} />
      <ContainerTitulo>
        <SpanTitulo>{titulo ? titulo : objetoPdf.titulo}</SpanTitulo>
        <SpanNumeroCar>{`CAR ${numeroCar}`}</SpanNumeroCar>
        <SpanNomeMunicipioFazenda>
          {`${objetoPdf?.municipioFazenda} - ${objetoPdf?.estado}`}
        </SpanNomeMunicipioFazenda>
      </ContainerTitulo>
    </Header>
  );

  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ReactToPrint
        onBeforePrint={() => dispatch(incrementLoad())}
        onAfterPrint={() => dispatch(decrementLoad())}
        trigger={() => (
          <div
            style={{
              width: "100vw",
              alignItems: "center",
              backgroundColor: "red",
              position: "relative",
              marginBottom: "50px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{ position: "absolute", left: "50%" }}
            >
              Imprimir
            </Button>
          </div>
        )}
        content={() => componentRef}
      />

      <div
        style={{
          backgroundColor: "#ECF0F6",
        }}
        ref={(el) => (componentRef = el)}
      >
        <FolhaA4 ref={(el) => (componentRef = el)}>
          <HeaderPdf />
          <PDFContainer>
            <br />
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 180,
              }}
            >
              <img
                src={objetoPdf.imagemEstadoImovel}
                alt="Descrição da Imagem"
                width={160}
                height={160}
              ></img>
              <img src={ImgZoom} alt="ZoomImovel" width={70} height={180}></img>
              <MapaGraticule codigoImovel={numeroCar} bbox={bbox} />
              <div>
                <span
                  style={{
                    marginLeft: "55px",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "initial",
                  }}
                >
                  Uso do Solo
                </span>
                <div style={{ marginLeft: "50px", paddingBottom: "15px" }}>
                  <img
                    src={objetoPdf.legendaUsoTerra}
                    alt="Legenda Usos"
                    width={200}
                    height={200}
                  ></img>
                </div>
              </div>
            </div>
            <EscalaGrafica bbox={bboxEscala} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <span>Usos 2008</span>
                <img
                  src={objetoPdf.imagemUsoTerra2008}
                  alt="Solos"
                  width={240}
                  height={240}
                ></img>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Usos 2011</span>
                <img
                  src={objetoPdf.imagemUsoTerra2011}
                  alt="Solos2011"
                  width={240}
                  height={240}
                ></img>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Usos 2016</span>
                <img
                  src={objetoPdf.imagemUsoTerra2016}
                  alt="SolosUltima"
                  width={240}
                  height={240}
                ></img>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Usos 2021</span>
                <img
                  src={objetoPdf.imagemUsoTerra2016}
                  alt="Solos2021"
                  width={240}
                  height={240}
                ></img>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            ></div>

            <RodapePdf>
              <ContainerTextoRodapeH>
                <TextoInfo>
                  {
                    "Fonte: Mapeamento de cobertura e uso da terra do Projeto Mapbiomas e Limite do imóvel rural do Cadastro Ambiental Rural - CAR."
                  }
                </TextoInfo>
                <TextoRodapePdf>
                  Embrapa. Sistema de apoio à caracterização ambiental de
                  imóveis rurais.
                </TextoRodapePdf>
                <TextoRodapePdf>
                  http://sacir.cnpma.embrapa.com.br
                </TextoRodapePdf>
              </ContainerTextoRodapeH>
              <TextoRodapePdf>{new Date().toLocaleDateString()}</TextoRodapePdf>
            </RodapePdf>
          </PDFContainer>
        </FolhaA4>

        <FolhaA4 ref={(el) => (componentRef = el)}>
          <HeaderPdf
            titulo={
              "Aptidão agrícola, tipo de solo e declividade do imóvel rural"
            }
          />
          <PDFContainer>
            <SpanTitulo2></SpanTitulo2>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 180,
              }}
            >
              <img
                src={objetoPdf.imagemEstadoImovel}
                alt="Descrição da Imagem"
                width={160}
                height={160}
              ></img>
              <img src={ImgZoom} alt="ZoomImovel" width={70} height={180}></img>
              <MapaGraticule codigoImovel={numeroCar} bbox={bbox} />
              <div
                style={{
                  marginLeft: "50px",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "20px",
                    color: "black",
                    fontWeight: "initial",
                  }}
                >
                  Declividade
                </span>
                <img
                  src={objetoPdf.legendaDeclividade}
                  alt="Legenda Solo"
                  width={190}
                  height={190}
                ></img>
              </div>
            </div>
            <EscalaGrafica bbox={bbox} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                marginTop: "50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SpanTitulo2>Declividade</SpanTitulo2>
                <img
                  src={objetoPdf.imagemDeclividade}
                  alt="Solos"
                  width={250}
                  height={250}
                ></img>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SpanTitulo2>Aptidão e Solo</SpanTitulo2>
                <img
                  src={objetoPdf.imagemSolo}
                  alt="Solos"
                  width={250}
                  height={250}
                ></img>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            ></div>

            <RodapePdf>
              <ContainerTextoRodapeH>
                <TextoInfo>
                  {
                    "Fonte: Mapeamento de cobertura e uso da terra do Projeto Mapbiomas e Limite do imóvel rural do Cadastro Ambiental Rural - CAR."
                  }
                </TextoInfo>
                <TextoRodapePdf>
                  Embrapa. Sistema de apoio à caracterização ambiental de
                  imóveis rurais.
                </TextoRodapePdf>
                <TextoRodapePdf>
                  http://sacir.cnpma.embrapa.com.br
                </TextoRodapePdf>
              </ContainerTextoRodapeH>
              <TextoRodapePdf>{new Date().toLocaleDateString()}</TextoRodapePdf>
            </RodapePdf>
          </PDFContainer>
        </FolhaA4>
      </div>
    </div>
  );
}
