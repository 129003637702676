import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icone from "./Icone";
import { clearReducer } from "../redux/reducer";
import { TituloHeader, TituloHeaderLink } from "./components-stilo";
import { useLocation } from "react-router-dom";
import pdfAvisoDePrivacidade from "../PdfDownloads/AvisoDePrivacidade.pdf";
import pdfTermoDeUso from "../PdfDownloads/TermoDeUso.pdf";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";
const Header = () => {
  const user = useSelector((state) => state.persistedReducer.user);
  const [rotaAtual, setRotaAtual] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  useEffect(() => {
    const atualizarLarguraTela = () => {
      setLarguraTela(window.innerWidth);
    };

    window.addEventListener("resize", atualizarLarguraTela);

    return () => {
      window.removeEventListener("resize", atualizarLarguraTela);
    };
  }, []);

  function MenuListComposition() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === "Tab") {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === "Escape") {
        setOpen(false);
      }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          justifyContent: "space-between",
          paddingRight: 10,
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing={2}>
          <div>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MenuIcon style={{ fontSize: 40 }} />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={() => navigate("/inicio")}>
                          Início
                        </MenuItem>
                        <MenuItem onClick={() => navigate("/sobre")}>
                          Sobre
                        </MenuItem>
                        <MenuItem onClick={() => navigate("/contato")}>
                          Contato
                        </MenuItem>
                        <MenuItem>
                          <a
                            style={{ textDecoration: "none", color: "#222" }}
                            href={pdfTermoDeUso}
                            download={"TermoDeUso.pdf"}
                          >
                            Aviso de Privacidade
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            style={{ textDecoration: "none", color: "#222" }}
                            href={pdfTermoDeUso}
                            download={"TermoDeUso.pdf"}
                          >
                            Termo de Uso
                          </a>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Stack>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 15 }}>
            <span
              style={{ color: "#024634", fontSize: 16, fontWeight: "bold" }}
            >
              {user.firstName}
            </span>
          </div>
          <div onClick={sair} style={{ cursor: "pointer" }}>
            <Icone nome={"sair"} size={40} />
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    setRotaAtual(location.pathname);
  }, [location]);

  const home = () => {
    navigate("/inicio");
  };

  const sair = () => {
    dispatch(clearReducer());
    navigate("/login");
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        color: "white",
        width: "100vw",
        height: "8vh",
        minWidth: 500,
      }}
    >
      {larguraTela > 700 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginLeft: 50 }}>
            <TituloHeader
              onClick={() => navigate("/inicio")}
              $temBorder={rotaAtual === "/inicio"}
            >
              Início
            </TituloHeader>
            <TituloHeader
              onClick={() => navigate("/sobre")}
              $temBorder={rotaAtual === "/sobre"}
            >
              {" "}
              Sobre
            </TituloHeader>
            <TituloHeader
              onClick={() => navigate("/contato")}
              $temBorder={rotaAtual === "/contato"}
            >
              Contato
            </TituloHeader>
            <TituloHeaderLink href={pdfTermoDeUso} download={"TermoDeUso.pdf"}>
              Aviso de Privacidade
            </TituloHeaderLink>
            <TituloHeaderLink href={pdfTermoDeUso} download={"TermoDeUso.pdf"}>
              Termo de Uso
            </TituloHeaderLink>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 100,
            }}
          >
            <div style={{ marginRight: 15 }}>
              <span
                style={{ color: "#024634", fontSize: 28, fontWeight: "bold" }}
              >
                {user.firstName}
              </span>
            </div>
            <div onClick={sair} style={{ cursor: "pointer" }}>
              <Icone nome={"sair"} size={40} />
            </div>
          </div>
        </div>
      ) : (
        <MenuListComposition />
      )}
    </div>
  );
};

export default Header;
