import { styled, css } from "styled-components";

export const SpanTitulo = styled.span`
  color: #024634;
  font-size: 38px;
  font-weight: bold;
  width: 80%;
`;

export const SpanSubtitulo = styled.span`
  color: #696969;
  font-size: 30px;
  font-weight: bold;
  width: 80%;
`;

export const DivGeral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DivContainer = styled.div`
  width: 80%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ContentResultado = styled.div`
  width: 100%;
  margin-top: 10px;

  ${(props) =>
    props.$marginCelular ?
    css`
      margin-bottom: 120px;
    ` : css`
      margin-bottom: 50px;
    `}
`;

export const ContainerComBorda = styled.div`
  width: 100%;
  border: solid 1px #024634;
  border-radius: 10px;
`;
export const ContainerInfoMapa = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const InfoMapa = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const ContainerMapa = styled.div`
  width: 100%;
  heigth: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContentSearch = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
`;
